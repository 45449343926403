.page-header {
  margin-bottom: 10px;
}

@media ($bp-larger-than-tablet) {

  .page-header {
    margin-top: 10px;
    margin-bottom: 30px;
  }

}

.container {
  padding: 0 10px !important;
}

.box {
  border: 4px solid #eee;
  padding: .5rem .8rem;
}

.center-me {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.shrink-me {
  width: 50%;
}

.bio-pic {
  float: right;
  width: 40%;
  margin: 0 0 10px 10px;
}

.clear-me {
  clear: both;
}
