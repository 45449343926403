h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

.lead {
  font-size: 2.2rem;
  font-style: italic;
  line-height: 2.9rem;
  text-align: center;
  padding: 0 10% 0 10%;
}

ul {
  margin-bottom: 2.5rem;
}

.blog-index h2 {
  font-size: 3rem;
  margin-bottom: .5rem;
}

.post-meta {
  color: #777;
  margin-bottom: 1.5rem;
  margin-top: -2rem;
}

h2 {
  font-size: 3rem;
  margin-bottom: .5rem;
}
