.intro-image {
  margin-bottom: 2rem !important;
}

.box-portfolio {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.box-portfolio ul {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 2rem;
}

.box-portfolio dl {
  margin: 0;
  line-height: 1.9rem;
}

.box-portfolio dt {
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.2rem;
}

.box-portfolio dt::after {
  content: ":";
}

.box-portfolio dd {
  margin-bottom: 1rem;
  margin-left: 0;
  margin-top: -0.4rem;
}

.box-portfolio li {
  margin-bottom: 0.5rem;
}

.box-portfolio li:last-child, .box dd:last-child {
  margin-bottom: 0;
}

.portfolio-description h2 {
  font-size: 2rem;
  margin-bottom: 0;
}

.portfolio-description p {
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.portfolio-description h2 a {
  color: #222;
  text-decoration: none;
}

.portfolio-year {
  color: #777;
  font-size: 1.3rem;
  margin: 0 !important;
}

.portfolio-item {
  margin-bottom: 4rem;
  content: "";
  display: table;
  clear: both;
}

.portfolio-image {
  float: left;
  width: 30%;
}

.portfolio-description {
  float: left;
  margin-left: 2%;
  width: 68%;
}

.additional-images p {
  margin-top: 40px;
  text-align: center;
}
