footer {
  border-top: 1px solid #ccc;
  color: #777;
  font-size: 1.3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

footer a {
  color: #777;
}
