.intro body {
    background: url(../assets/images/layout/running-downhill-in-are.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media ($bp-larger-than-mobile) {

  .intro body {
      background: url(../assets/images/layout/running-downhill-in-are-bigger-screens.jpg) no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
  }

}

.intro footer {
  display: none;
}

.box-intro {
  background: #fff;
  font-size: 1.7rem;
  opacity: .9;
  padding: 10px;
}

.box-intro p:last-child {
  margin-bottom: 0;
}

@media ($bp-larger-than-tablet) {

  .box-intro {
    float: right;
    font-size: 1.7rem;
    margin-right: 10px;
    margin-top: 80px;
    opacity: .9;
    padding: 10px;
    width: 50%;
  }

}
