nav {
  border-bottom: 1px solid #eee;
  text-align: center;
}

nav a {
  color: #000;
  text-decoration: none;
}

nav ul {
  margin-bottom: 1rem;
  margin-top: 0;
}

.nav-brand {
  font-size: 3rem;
  font-weight: bold;
}

.nav-list {
  list-style: none;
  padding-left: 0;
}

.nav-list li {
  display: inline;
}

.nav-list li+li {
  margin-left: 1.5rem;
}

.active {
  font-weight: bold;
}

@media (min-width: 764px) {

  nav {
    text-align: left;
  }

  .nav-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-list {
    float: right;
    margin-top: 1.3rem;
  }

  .nav-list li {
    font-size: 1.6rem;
    float: left;
  }

  .nav-list li+li {
    margin-left: 1rem;
  }

  .nav-list a {
    padding: 1.5rem;
  }

  .last-nav-item {
    padding-right: 0 !important;
  }

}
